const getRoute = (link) => {
  if (/\d{11}/g.test(link.toString())) {
    return `tel:+${link}`;
  }

  const route = link?.externalLinkOrAnchor
    ? link?.externalLinkOrAnchor
    : link?.internalLink?.handle
    ? `/${link?.internalLink?.handle}`
    : null;

  return route === '//' ? '/' : route;
};

export default getRoute;
