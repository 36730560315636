import React, { useState, useRef, useEffect, useContext } from 'react';
import { gsap, Power1 } from 'gsap';

import ThemeLink from 'components/UI/ThemeLink';
import Container from 'components/Container';
import getRoute from 'utils/functions/getRoute';
import { useDeviceDetect } from 'utils/hooks/useDeviceDetect';
import {
  HomeWrapper,
  HomeBodyWrapper,
  HomeBody,
  HomeBodyText,
  SiteLinksWrapper,
  SiteLinkWrapper,
  HomeBodyLine,
} from './styles';
import {
  LinkExcerpt,
  LinkCardWrapper,
} from 'components/Layouts/ParentResources/styles';

import linksAnimation from 'utils/animations/linksAnimation';
import homeBodyAnimation from 'utils/animations/homeBodyAnimation';

import AnimationContext from 'context/AnimationProvider';

const Home = (props) => {
  const { modules, title } = props?.data?.contentfulHomePage;
  const { isMobile } = useDeviceDetect();
  const [showExcerpt, setShowExcerpt] = useState({});

  const { animationExit } = useContext(AnimationContext);

  const homeBodyRef = useRef();
  const linksRef = useRef();
  const tl = useRef(null);

  useEffect(() => {
    const homeBodyTl = homeBodyAnimation(homeBodyRef);
    const linkAnimTl = linksAnimation(linksRef);

    tl.current = gsap.timeline().add(homeBodyTl).add(linkAnimTl, '-=1.4');

    return () => {
      tl.current.kill();
    };
  }, []);

  useEffect(() => {
    if (animationExit) {
      setShowExcerpt({});
      tl.current.timeScale(3).reverse(0);
    }
  }, [animationExit]);

  const itemHoverOn = (inx) => {
    setShowExcerpt((state) => ({
      ...state,
      [inx]: true,
    }));
  };

  const itemHoverOut = (inx) => {
    setShowExcerpt((state) => ({
      ...state,
      [inx]: false,
    }));
  };

  const exitPage = () => {
    setShowExcerpt({});
    tl.current.timeScale(2).reverse(0);
  };

  const children = (body, visible) => {
    const ref = useRef();

    return (
      <LinkExcerpt className="LinkExcerpt" ref={ref}>
        {body}
      </LinkExcerpt>
    );
  };

  return (
    <HomeWrapper>
      <Container
        bottomSpacingTablet="10"
        spacedTablet={true}
        spacedDesktop={false}
      >
        <HomeBodyWrapper ref={homeBodyRef}>
          <HomeBodyLine id="home-body-line"></HomeBodyLine>
          {title && (
            <HomeBody role="heading">
              <HomeBodyText theme="body-l">{title}</HomeBodyText>
            </HomeBody>
          )}
        </HomeBodyWrapper>
        <SiteLinksWrapper role="navigation" ref={linksRef}>
          {modules &&
            modules.map((module, index) => {
              return module.title ? (
                <SiteLinkWrapper key={module.id} onClick={exitPage}>
                  <LinkCardWrapper
                    onMouseEnter={() => !isMobile && itemHoverOn(index)}
                    onMouseLeave={() => !isMobile && itemHoverOut(index)}
                    className={`${showExcerpt[index] ? 'is-hovered' : ''}`}
                  >
                    <ThemeLink
                      to={getRoute(module)}
                      childEl={
                        module.excerpt &&
                        children(module.excerpt, showExcerpt[index])
                      }
                      bordered={index !== modules.length - 1}
                      topSpacingMobile="20"
                      bottomSpacingMobile="20"
                      bottomSpacingTablet="30"
                      bottomSpacingDesktop="20"
                      exitDelay={2}
                    >
                      {module.title}
                    </ThemeLink>
                  </LinkCardWrapper>
                </SiteLinkWrapper>
              ) : (
                <></>
              );
            })}
        </SiteLinksWrapper>
      </Container>
    </HomeWrapper>
  );
};

export default Home;
