import { gsap, Power1 } from 'gsap';

const homeBodyAnimation = (ref) => {
  const line = ref.current.querySelector('#home-body-line');
  const title = ref.current.querySelector('[role="heading"]');

  const tl = gsap
    .timeline()
    .fromTo(ref.current, { opacity: 0 }, { opacity: 1, duration: 0 })
    .fromTo(
      line,
      { height: 0 },
      { height: '100%', duration: 0.5, ease: Power1.easeOut },
      0.3
    )
    .fromTo(
      title,
      { autoAlpha: 0, x: '-100%' },
      { autoAlpha: 1, x: 0, duration: 0.5, ease: Power1.easeOut },
      0.5
    );

  return tl;
};

export default homeBodyAnimation;
