import React from 'react';
import PropTypes from 'prop-types';
import Home from 'components/Layouts/Home';
import { graphql } from 'gatsby';

const Index = (props) => {
  return <Home {...props} />;
};

export default Index;

Index.propTypes = {
  location: PropTypes.object.isRequired,
};

export const query = graphql`
  query {
    contentfulHomePage(handle: { eq: "/" }) {
      contentful_id
      handle
      id
      seo {
        openGraphTitle
        openGraphDescription
        seoTitle
        seoDescription
        openGraphImage {
          title
          file {
            url
          }
        }
      }
      title
      modules {
        externalLinkOrAnchor
        openInNewTab
        title
        excerpt
        id
        internalLink {
          __typename
          ... on ContentfulBlogPage {
            id
            handle
          }
          ... on ContentfulGenericPage {
            id
            handle
          }
          ... on ContentfulParentResourcesPage {
            id
            handle
          }
        }
      }
    }
  }
`;
