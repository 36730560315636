import styled from 'styled-components';

export const HelpWrapper = styled.div`
  @media (min-width: ${(props) => props.theme.breakpoints.s}) {
    margin-top: 80px;
  }

  @media (min-width: 1680px) {
    margin-top: 0px;
  }
  .Help-Link {
    @media (min-width: ${(props) => props.theme.breakpoints.s}) {
      font-size: 24px;
    }

    @media (min-width: ${(props) => props.theme.breakpoints.l}) {
      font-size: 36px;
    }
  }
`;

export const LinkCardsWrapper = styled.ul`
  margin: 0;
  padding: 0;
  list-style-type: none;
  opacity: 0;

  > *:first-of-type {
    margin-top: 0;
  }
`;

export const LinkCardWrapper = styled.li`
  margin: 0;
  padding: 0;
  transition: padding 750ms ease;
  will-change: padding;

  svg {
    transition: transform 0.4s;
  }

  &.is-hovered {
    padding: 5px 0;

    .Site-Link-Body {
      padding-bottom: 26px;
    }

    .LinkExcerpt {
      opacity: 1 !important;
      max-width: 70%;
    }

    svg {
      transform: translate(-15px, 0px) !important;
    }
  }
`;

export const LinkExcerpt = styled.p`
  &.LinkExcerpt {
    grid-column: 1/3;
    grid-row: 1/2;
    color: ${(props) => props.theme.colors.white};
    opacity: 0 !important;
    font-size: ${(props) => props.theme.fonts.body_mobile};
    font-weight: ${(props) => props.theme.fontWeight.body_mobile};
    line-height: ${(props) => props.theme.lineHeight.body_mobile};
    margin-bottom: 20px;
    position: absolute;
    bottom: 0;
    transition: 0.5s ease;

    @media (min-width: ${(props) => props.theme.breakpoints.s}) {
      font-size: ${(props) => props.theme.fonts.body_tablet};
      font-weight: ${(props) => props.theme.fontWeight.body_tablet};
      line-height: ${(props) => props.theme.lineHeight.body_tablet};
      padding-right: 40px;
    }

    @media (min-width: ${(props) => props.theme.breakpoints.l}) {
      font-size: ${(props) => props.theme.fonts.body_desktop};
      font-weight: ${(props) => props.theme.fontWeight.body_desktop};
      line-height: ${(props) => props.theme.lineHeight.body_desktop};
    }

    @media (min-width: ${(props) => props.theme.breakpoints.xl}) {
      font-size: ${(props) => props.theme.fonts.body_large_desktop};
      font-weight: ${(props) => props.theme.fontWeight.body_large_desktop};
      line-height: ${(props) => props.theme.lineHeight.body_large_desktop};
    }
  }
`;
